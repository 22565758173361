<!--
 * @Author: ch3nh2
 * @Date: 2021-10-21 19:22:44
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-08-19 14:45:13
 * @FilePath: /zhy_dataview_fe/src/pages/login.vue
 * @Description: none
-->
<template>
  <el-form :model="form" :rules="rules" ref="form">
    <div class="login-container">
      <div class="backround"></div>
      <div class="content">
        <div class="title"></div>
        <div class="login">
          <div class="border"></div>
          <div class="input-wrap">
            <el-form-item prop="account">
              <div class="input-item">
                <el-input clearable v-model="form.account" maxlength="11" placeholder="请输入您的账号"></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="password">
              <div class="input-item">
                <el-input clearable v-model="form.password" maxlength="20" placeholder="请输入您的密码"></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="code">
              <div class="input-code">
                <div class="input-code-item">
                  <el-input clearable v-model="form.code" maxlength="4" placeholder="请输入验证码"></el-input>
                </div>
                <div class="input-code-item code-item" @click="getCode">
                  <img :src="codeImg" alt="">
                </div>
              </div>
            </el-form-item>
          </div>
          <div class="btn" @click="onSubmit('form')">登录</div>
        </div>
      </div>
    </div>
  </el-form>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      form: {
        account: "",
        password: "",
        code: "",
      },
      codeImg: '',
      codeKey: '',
      rules: {
        account: [
          {
            required: true,
            message: "请先输入您的账号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请先输入您的密码",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请先输入验证码",
            trigger: "blur",
          },
          {
            min: 4,
            max: 4,
            message: "请输入四位数验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  components: {},
  props: {},
  methods: {
    getCode() {
      this.$api.getImgCode().then((res) => {
        const { code, data } = res;
        if (code == 'B200') {
          this.codeImg = data.data;
          this.codeKey = data.redisKey;
          if (this.$config.dev) {
            this.form.code = data.code
          }
        }
      })
    },
    onSubmit(formName) {
      const _self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api
            .userLogin({
              thirdPartyId: this.form.account,
              userName: this.form.account,
              password: this.form.password,
              verCode: this.form.code,
              verCodeKey: this.codeKey
            })
            .then((res) => {
              if (res) {
                const { msg, data } = res;
                if (msg == "ok") {
                  const { token } = data;
                  this.$router.push("/");
                  this.$store.dispatch("setToken", token);
                  this.$JX.login(this.form.account, this.form.password)
                  localStorage.setItem("ACCESS_TOKEN", token);
                  localStorage.setItem("JX_ACCOUNT", this.form.account);
                  localStorage.setItem("JX_PASSWORD", this.form.password);
                  localStorage.setItem("ACCESS_PERMISSIONS", res.data.permissions);
                }
              }
            });
        } else {
          return false;
        }
      });
    },
  },
  created() {
    const loginForm = {
      account: "admin001",
      password: "1234abcd",
      code: "",
    };
    if (this.$config.dev) {
      this.form = loginForm;
    }
  },
  mounted() {
    this.getCode()
  },
  beforeUnmount() { },
};
</script>

<style lang="scss">
.el-form {
  position: absolute;
  left: 0;
  top: 0;
  width: 1920px;
  height: 1080px;
  z-index: 2;
}

.login-container {
  height: 100%;
  padding-top: 0;
  background: #000715;

  .backround {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 1920px;
    height: 1080px;
    margin-left: -962.5px;
    background: #000715;
    background-image: url("../assets/image/pages/login/backround.png");
    background-size: cover;
    background-repeat: no-repeat;
    animation: pulse 8s infinite ease-in-out;
  }

  .content {
    position: absolute;
    width: 536px;
    height: 100%;
    left: 50%;
    top: 50%;
    margin-top: -180px;
    margin-left: -268px;

    .title {
      position: absolute;
      top: 0px;
      margin-top: -100px;
      left: 50%;
      width: 366px;
      height: 39px;
      margin-left: -168px;
      background-image: url("../assets/image/pages/login/title.png");
      background-size: 100%;
      background-repeat: no-repeat;
      animation: bigScaleIn 0.5s both ease;
    }

    .login {
      width: 536px;
      height: 469px;
      background-image: url("../assets/image/pages/login/login.png");
      background-size: 100%;
      background-repeat: no-repeat;
      animation: scaleIn 0.5s 0.5s both ease;

      .border {
        position: absolute;
        top: 20px;
        left: 15px;
        width: 508px;
        height: 425px;
        background-image: url("../assets/image/pages/login/border.png");
        background-size: 100%;
        background-repeat: no-repeat;
        animation: fadeInOut 3s infinite ease-in-out;
      }

      .input-wrap {
        position: absolute;
        width: 400px;
        height: 184px;
        top: 130px;
        left: 50%;
        margin-left: -200px;
        user-select: none;

        .input-item {
          width: 100%;
          height: 48px;
          background-color: #0d2257;
          border-radius: 6px;
          color: #fff;
        }

        .input-code {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .input-code-item {
            height: 48px;
            background-color: #0d2257;
            border-radius: 6px;
          }

          .code-item {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            cursor: pointer;

            img {
              border-radius: 6px;
            }
          }
        }
      }

      .btn {
        position: absolute;
        left: 50%;
        bottom: 76px;
        width: 400px;
        height: 48px;
        margin-left: -200px;
        background: linear-gradient(to top, #4ec0ef, #4876e0);
        border-radius: 2px;
        color: #fff;
        text-align: center;
        line-height: 48px;
        font-size: 18px;
        cursor: pointer;
        transition: all 0.2s ease-out;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}

.el-form-item {
  margin-bottom: 20px;
}
</style>
